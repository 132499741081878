import { Pageable } from './interfaces/pageable.interface';

export enum Role {
  ADMIN = 'ADMIN',
  MASTER = 'MASTER',
  BASIC = 'BASIC'
}

export class Constants {
  private static configServiceBaseURL = '/config-service';

  public static SERVICES_URL = `/api/v1/services`;
  public static CUSTOMER_SERVICES_URL = `/api/v1/customer-services`;
  public static SERVICE_BY_BRAND_URL = `api/v1/brands`;
  public static WHITELIST_SERVICE_URL = `${Constants.configServiceBaseURL}/whitelist`;

  public static NEWSLETTERS_URL = `/api/v1/admin/newsletters`;
  public static DEFAULT_RESPONSES_URL = `api/v1/default-responses`;

  public static DEFAULT_PAGEABLE: Pageable = {
    page: 0,
    size: 10
  };

  public static ALL_ROLES: Role[] = Object.keys(Role).map((role) => Role[role]);

  public static serviceUri(brand: string, modId: string) {
    return `${Constants.SERVICE_BY_BRAND_URL}/${brand}/services/${modId}`;
  }
}
