import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './shared/services/user-service.service';
import { filter, take } from 'rxjs/operators';
import { User } from './shared/models/user.model';
import { environment } from './environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'e2e-diagnose';
  userService: UserService;
  user: User;

  constructor(translate: TranslateService, userService: UserService) {
    this.userService = userService;
    translate.addLangs(environment.regions);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.regions[1]);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(environment.regions[0]);
  }

  shouldDisplayPreloader(): boolean {
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe((x) => {
        this.user = x;
      });
    return this.user ? true : false;
  }
}
