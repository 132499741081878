import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Solution } from '../../../../../shared/interfaces/failure.interface';
import { environment } from '../../../../../environment';

@Component({
  selector: 'app-solution-input',
  templateUrl: './solution-input.component.html',
  styleUrls: ['./solution-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SolutionInputComponent),
      multi: true
    }
  ]
})
export class SolutionInputComponent implements OnInit, ControlValueAccessor {
  value: Solution = {};
  selectedLanguage: string;
  public roles: string[] = environment.failureRoles;
  @Input() languages: string[] = undefined;

  writeValue(value: Solution) {
    this.value = value ? value : {};
    this.selectedLanguage = this.getLanguages()[0];
  }
  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor() {}

  ngOnInit(): void {
    this.selectedLanguage = this.getLanguages()[0];
  }

  getLanguages() {
    return this.languages ? this.languages : this.estimateLanguages();
  }

  private estimateLanguages = () => {
    const flat = this.roles
      .map((r) => this.value[r])
      .map((loc) => (loc ? Object.keys(loc) : []))
      .reduce((acc, it) => [...acc, ...it]);

    return [...new Set(flat)];
  };

  get selectedLanguageMod() {
    return this.selectedLanguage;
  }

  set selectedLanguageMod(lang) {
    this.selectedLanguage = lang;
  }

  getValue(role: string) {
    return this.value[role] ? this.value[role][this.selectedLanguage] : undefined;
  }

  setValue(val: string, role: string) {
    if (!this.value[role]) {
      this.value[role] = {};
    }

    this.value[role][this.selectedLanguage] = val;
  }
}
