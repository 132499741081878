import { Service } from '../../shared/interfaces/service.interface';
import { RolesService } from '../../shared/services/roles-service.service';
import { Output, EventEmitter, Directive } from '@angular/core';
import { ServiceClient } from '../../shared/services/services.service';

@Directive()
export abstract class ItemsBaseDirective<T> {
  @Output() numberChanged = new EventEmitter<number>();
  allItems: T[];
  itemsToDisplay: T[];
  filters = [];
  loadedPercentage;

  public editedItem: T;

  constructor(protected serviceClient: ServiceClient<T>, public rolesService: RolesService) {}

  protected refreshItems() {
    this.serviceClient.getItems().subscribe((data: T[]) => {
      this.allItems = data;
      this.itemsToDisplay = this.visibilityFilter(this.allItems);
      this.loadedPercentage = (this.itemsToDisplay.length / this.allItems.length) * 100;
      this.numberChanged.emit(data.length);
    });
  }

  private visibilityFilter = (services: T[]) => services;

  setVisibilityFilter(filter: (services: T[]) => T[]) {
    this.visibilityFilter = filter;
    if (this.allItems) {
      this.itemsToDisplay = this.visibilityFilter(this.allItems);
      this.loadedPercentage = (this.itemsToDisplay.length / this.allItems.length) * 100;
    }
  }

  hasAnyMaster(): boolean {
    return this.rolesService.getBrandsWhereMaster().length > 0;
  }

  isMaster = (service: Service) => {
    return this.rolesService.getBrandsWhereMaster().includes(service.brand);
  };

  abstract itemConstructor(): T;

  public createNewItem() {
    this.editedItem = this.itemConstructor();
  }

  editWindowClosed() {
    this.editedItem = null;
  }

  itemSubmitted(service: T) {
    this.serviceClient.saveItem(service).subscribe(() => {
      this.editWindowClosed();
      this.refreshItems();
    });
  }

  onEdit(service: T) {
    this.editedItem = service;
  }

  onDelete(service: T) {
    this.serviceClient.deleteItem(service).subscribe(() => this.refreshItems());
  }

  loadRemainingData() {
    this.filters.length = 0;
    this.setVisibilityFilter((services: T[]) => services);
  }

  isAllShown() {
    return this.itemsToDisplay?.length === this.allItems?.length;
  }

  onFilterUpdated() {
    this.setVisibilityFilter((services: T[]) =>
      services.filter((service: T) =>
        this.filters.every((filterTagValue) =>
          this.fieldsToFilterOn().some((parameter) =>
            service[parameter]?.toLowerCase().includes(filterTagValue.toLowerCase())
          )
        )
      )
    );
  }

  abstract fieldsToFilterOn(): string[];
}
