import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppComponent } from './app.component';
import { AffirmationServiceModalComponent } from './components/affirmation-modal/affirmation-modal.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CreateServiceModalComponent } from './components/services/core/core-service-edit/core-service-edit.component';
import { FailureModalComponent } from './components/failure/failure-modal/failure-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { HeaderComponent } from './components/header/header.component';
import { MoreOptionsMenuComponent } from './components/more-options-menu/more-options-menu.component';
import { NotificationListComponent } from './components/notification/notification.component';
import { UnathorizedAccessComponent } from './components/unathorized-access/unathorized-access.component';
import { DefaultOAuthInterceptor } from './oauthinterceptor';
import { AdminAreaComponent } from './pages/admin-area/admin-area.component';
import { ContactpersonsComponent } from './pages/contactpersons/contactpersons.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DsgvoComponent } from './pages/dsgvo/dsgvo.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ServiceDetailComponent } from './pages/service-detail/service-detail.component';
import { RolesService } from './shared/services/roles-service.service';
import { CommonModule } from '@angular/common';
import { NewslettersDashboardComponent } from './pages/newsletters-dashboard/newsletters-dashboard.component';
import { CreateNewsletterModalComponent } from './components/create-newsletter-modal/create-newsletter-modal.component';
import { CoreServicesComponent } from './components/services/core/core-services/core-services.component';
import { CustomerServicesComponent } from './components/services/customer/customer-services/customer-services.component';
import { CustomerServiceEditComponent } from './components/services/customer/customer-service-edit/customer-service-edit.component';
import { ServiceItemComponent } from './components/services/service-item/service-item.component';
import { MultiValueInputComponent } from './components/services/core/core-service-edit/multi-value-input/multi-value-input.component';
import { LocalizationInputComponent } from './components/services/core/core-service-edit/localization-input/localization-input.component';
import { SolutionInputComponent } from './components/services/core/core-service-edit/solution-input/solution-input.component';
import { FailuresComponent } from './components/failure/failures/failures.component';
import { FailureItemComponent } from './components/failure/failure-item/failure-item.component';

const appRoutes: Routes = [
  { path: 'login', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'brand/:brand/service/:modId', component: ServiceDetailComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'admin/whitelist', component: AdminAreaComponent },
  { path: 'admin/newsletters', component: NewslettersDashboardComponent },
  { path: 'dsgvo', component: DsgvoComponent },
  { path: 'contact', component: ContactpersonsComponent },
  {
    path: 'profile',
    component: ProfilePageComponent
  }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    ServiceDetailComponent,
    CreateServiceModalComponent,
    MoreOptionsMenuComponent,
    AffirmationServiceModalComponent,
    AdminAreaComponent,
    UnathorizedAccessComponent,
    NotificationListComponent,
    DsgvoComponent,
    ContactpersonsComponent,
    FailureModalComponent,
    FormErrorComponent,
    ProfilePageComponent,
    AvatarComponent,
    NewslettersDashboardComponent,
    CreateNewsletterModalComponent,
    CoreServicesComponent,
    CustomerServicesComponent,
    CustomerServiceEditComponent,
    ServiceItemComponent,
    CoreServicesComponent,
    MultiValueInputComponent,
    LocalizationInputComponent,
    SolutionInputComponent,
    FailuresComponent,
    FailureItemComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot()
  ],
  providers: [
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true
    },
    RolesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
