import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from './environment';

export const authConfig: AuthConfig = {
  issuer: environment.issuer,
  redirectUri: window.location.origin,
  clientId: environment.clientId,
  // responseType: 'code',
  scope: 'openid profile email offline_access',
  showDebugInformation: true
};
