import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';

import { NotificationService } from '../../components/notification/notification.service';
import { AbstractItemService } from './services.service';
import { Failure } from '../interfaces/failure.interface';
import { CoreService } from '../interfaces/service.interface';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

export class FailureService extends AbstractItemService<Failure> {
  baseUrl: string;
  constructor(service: CoreService, http: HttpClient, notifications: NotificationService) {
    super(http, notifications);
    this.baseUrl = `${Constants.SERVICE_BY_BRAND_URL}/${service.brand}/services/${service.modId}/failures`;
  }

  allItemsEndpoint = () => this.baseUrl;
  saveItemEndpoint = (failure) => `${this.baseUrl}/${failure.name}`;
  deleteItemEndpoint = (failure) => `${this.baseUrl}/${failure.name}`;

  deleteFailures(names: string[]) {
    const httpOptions: any = {
      headers: { 'Content-Type': 'application/json' },
      body: { names }
    };

    return this.http.delete(this.baseUrl, httpOptions).pipe(catchError(this.handleError('deletion', null)));
  }
}

@Injectable({
  providedIn: 'root'
})
export class FailureServiceFactory {
  constructor(private http: HttpClient, private notifications: NotificationService) {}

  generateFailureService(coreService: CoreService): FailureService {
    return new FailureService(coreService, this.http, this.notifications);
  }
}
