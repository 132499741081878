<div class="dashboard-component" *ngIf="rolesService.checkIfUserHasPermission(MASTER_ROLE); else elseBlock">
  <div class="row row--container mar-top-large">
    <div class="col sm-12">
      <div class="card summary-card">
        <div class="card__content">
          <h5 class="left" name="dienste-tag">
            {{ 'NEWSLETTER-DASHBOARD.TITLE' | translate }}
          </h5>
          <div class="tag tag--secondary mar-left-large" name="service-number">
            {{ newsletters.length }}
          </div>
        </div>
        <button
          id="add-newsletter-button"
          class="btn btn--fab btn--primary add-button"
          type="submit"
          (click)="addNewsletter()"
        >
          <i class="icon i-close"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="row row--container mar-bottom-large" style="min-height: 300px">
    <div class="col sm-4 flex flex--equal-size" *ngFor="let newsletter of newsletters" name="newsletters-window">
      <div class="card mar-top-large">
        <div name="card_content" class="card__content pad-medium">
          <div class="flex flex--justify-space-between pad-small">
            <h1 class="h5 cursor-default flex__item flex__item--flex-auto">
              {{ newsletter.title[selectedLanguage] || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate }}
            </h1>
            <app-more-options-menu
              class="newsletter-more-options flex__item flex__item--flex-none"
              [elementName]="newsletter.id"
              [(selectedElement)]="openedOptionsMenuElement"
            >
              <div class="pad-small">
                <a
                  (click)="onNewsletterEditClick(newsletter)"
                  style="cursor: pointer"
                  id="edit-link-{{ newsletter.id }}"
                  ><i class="icon i-edit"></i>{{ 'COMMON.EDIT' | translate }}</a
                >
              </div>
              <div class="pad-small pad-top-erase">
                <a
                  (click)="onDeleteNewsletterClick(newsletter)"
                  id="delete-link-{{ newsletter.id }}"
                  style="cursor: pointer"
                  ><i class="icon i-delete"></i>{{ 'COMMON.DELETE' | translate }}</a
                >
              </div>
            </app-more-options-menu>
            <div class="clearfix"></div>
          </div>

          <div
            id="open-newsletter-edit-{{ newsletter.id }}"
            class="card mar-top-medium newsletter-summary cursor-pointer col"
            (click)="onNewsletterEditClick(newsletter)"
          >
            <div class="card__content pad-small flex flex--equal-size">
              {{ newsletter.content[selectedLanguage] || 'NEWSLETTER-DASHBOARD.EMPTY-CONTENT' | translate }}
            </div>
          </div>
          <div class="mar-top-medium">
            <i class="icon i-clock"></i>
            <b class="pad-left-small">{{ 'NEWSLETTER-DASHBOARD.EXPIRATION' | translate }}:</b>
            {{ newsletter.expirationDate | date: dateFormat }}
          </div>
        </div>
      </div>
    </div>

    <app-affirmation-modal
      (closed)="onDeleteModalClosed($event)"
      *ngIf="!!deleteNewsletterObject"
      [title]="'NEWSLETTER-DASHBOARD.AFFIRMATION-MODAL.TITLE' | translate"
      [content]="
        'NEWSLETTER-DASHBOARD.AFFIRMATION-MODAL.CONTENT'
          | translate: { x: deleteNewsletterObject.title[selectedLanguage] }
      "
      [confirmContent]="'NEWSLETTER-DASHBOARD.AFFIRMATION-MODAL.CONFIRM' | translate"
      [cancelContent]="'NEWSLETTER-DASHBOARD.AFFIRMATION-MODAL.CANCEL' | translate"
    >
    </app-affirmation-modal>
  </div>
</div>

<ng-template #elseBlock>
  <app-unathorized-access></app-unathorized-access>
</ng-template>

<app-create-newsletter-modal
  (closed)="onCreateNewsletterClosed($event)"
  *ngIf="createNewsletterModalOpened"
  [editNewsletterObject]="editNewsletterObject"
>
</app-create-newsletter-modal>
