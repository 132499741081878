import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CustomerService, CoreService } from '../../../../shared/interfaces/service.interface';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Generation } from '../../../../shared/interfaces/service.interface';
import { ServiceEditBaseDirective } from '../../service-edit-base.directive';

@Component({
  selector: 'app-customer-service-edit',
  templateUrl: './customer-service-edit.component.html',
  styleUrls: ['./customer-service-edit.component.scss']
})
export class CustomerServiceEditComponent extends ServiceEditBaseDirective<CustomerService> implements OnInit {
  private modIds = new FormArray([]);

  public MOD_IDS_KEY = 'modIds';

  @Input() servicesProvider: () => Map<string, Map<Generation, CoreService[]>>;
  public brandServices: CoreService[];

  brands() {
    return Array.from(this.servicesProvider().keys());
  }

  ngOnInit() {
    this.initCommonFields();
    this.form.addControl(this.MOD_IDS_KEY, this.modIds);

    this.updateServices();
    if (!this.creationMode) {
      this.initialObject.modIds.forEach((s) => this.selectService(s));
    }
  }

  onCheckChange(event) {
    if (event.target.checked) {
      this.selectService(event.target.value);
    } else {
      let i = 0;
      this.modIds.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          this.modIds.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  selectService(modId: string) {
    this.modIds.push(new FormControl(modId));
  }

  updateServices = () => {
    this.brandServices = this.servicesProvider()
      .get(this.form.get(this.BRAND_KEY).value)
      .get(this.form.get(this.GENERATION_KEY).value);
    this.modIds.clear();
  };
}
