import { Generation, Service } from '../../shared/interfaces/service.interface';
import { RolesService } from '../../shared/services/roles-service.service';
import { Output, EventEmitter, Directive } from '@angular/core';
import { ServiceClient } from '../../shared/services/services.service';
import { ItemsBaseDirective } from './items-base.directive';

@Directive()
export abstract class ServicesBaseDirective<T extends Service> extends ItemsBaseDirective<T> {
  itemConstructor() {
    return { generation: Generation.MOD_4 } as T;
  }
}
