<div class="modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      {{ 'CREATE_SERVICE_MODAL.MODAL_TITLE_' + (creationMode ? 'CREATE' : 'EDIT') | translate }}
    </h1>
    <div class="modal__close" (click)="close()"></div>
    <div class="modal__content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="row">
          <div class="col lg-7 md-7" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
            <label for="name">
              {{ 'CREATE_SERVICE_MODAL.NAME' | translate }}
              <input class="form-control" formControlName="{{ NAME_KEY }}" type="text" id="name" name="name" />
            </label>
          </div>

          <div class="col lg-5 md-5" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
            <label for="modId">
              {{ 'CREATE_SERVICE_MODAL.MOD_ID' | translate }}
              <input class="form-control" formControlName="{{ MOD_ID_KEY }}" type="text" id="modId" name="modId" />
            </label>
          </div>
          <app-form-error [visible]="form.controls[NAME_KEY].hasError('required') && submitted">
            {{ 'CREATE_SERVICE_MODAL.FIELD_SERVICE_NAME_REQUIRED' | translate }}
          </app-form-error>
          <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('required') && submitted">
            {{ 'CREATE_SERVICE_MODAL.FIELD_MOD_ID_REQUIRED' | translate }}
          </app-form-error>

          <div class="col lg-4 md-4" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
            <label for="brand">
              {{ 'CREATE_SERVICE_MODAL.BRAND' | translate }}
              <div class="wrapper wrapper--select">
                <select id="brand" name="brand" formControlName="{{ BRAND_KEY }}" (change)="updateServices()">
                  <option *ngFor="let brand of brands()" [value]="brand">
                    {{ brand }}
                  </option>
                </select>
              </div>
            </label>
          </div>

          <div class="col lg-4 md-4">
            <label for="generation">
              {{ 'CREATE_SERVICE_MODAL.GENERATION' | translate }}
              <div class="wrapper wrapper--select">
                <select
                  id="generation"
                  name="generation"
                  formControlName="{{ GENERATION_KEY }}"
                  (change)="updateServices()"
                >
                  <option *ngFor="let generation of GENERATION_KEYS" [ngValue]="Generation[generation]">
                    {{ Generation[generation] }}
                  </option>
                </select>
              </div>
            </label>
          </div>

          <div class="col lg-4 md-4">
            <label for="date">
              {{ 'CREATE_SERVICE_MODAL.DATE' | translate }}
              <input class="form-control" formControlName="{{ DATE_KEY }}" id="date" type="date" name="date" />
            </label>
          </div>

          <div class="col sm-12" formArrayName="modIds">
            <label for="modIds">
              {{ 'DASHBOARD.CORE_SERVICES' | translate }}
            </label>

            <div *ngFor="let service of brandServices; index as i">
              <div>
                <input
                  type="checkbox"
                  [id]="'selected_service_' + service.modId"
                  name="{{ service.modId }}"
                  [value]="service.modId"
                  [checked]="initialObject.modIds ? initialObject.modIds.includes(service.modId) : false"
                  (change)="onCheckChange($event)"
                />
                <label [for]="'selected_service_' + service.modId">{{ service.name }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col sm-8"></div>
          <div class="col sm-4">
            <button type="submit" name="acceptButton" class="btn btn--primary btn--expanded save-modal right">
              {{ 'CREATE_SERVICE_MODAL.BUTTON_SAVE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
