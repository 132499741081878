import { Component, OnInit } from '@angular/core';

import { CoreService } from '../../../../shared/interfaces/service.interface';
import { RolesService } from '../../../../shared/services/roles-service.service';
import { ServicesBaseDirective } from '../../services-base.directive';
import { Router } from '@angular/router';
import { CoreServiceClient } from 'src/app/shared/services/services.service';
import { environment } from '../../../../environment';

@Component({
  selector: 'app-core-services',
  templateUrl: './core-services.component.html',
  styleUrls: ['./core-services.component.scss']
})
export class CoreServicesComponent extends ServicesBaseDirective<CoreService> implements OnInit {
  features: Map<string, boolean>;

  constructor(services: CoreServiceClient, rolesService: RolesService, private router: Router) {
    super(services, rolesService);
  }

  ngOnInit(): void {
    this.setVisibilityFilter((s: CoreService[]) => s.slice(0, 9));
    this.refreshItems();
    this.features = environment.features;
  }

  navigateToServiceDetails(service: CoreService) {
    if (this.isMaster(service) || service.ownerIds?.some(this.rolesService.isCurrentUser)) {
      this.router.navigate(['/brand/' + service.brand + '/service/' + service.modId]);
    } else {
      console.log('user not allowed to see service content');
    }
  }

  fieldsToFilterOn() {
    return ['name', 'modId', 'brand', 'ownerName', 'generation', 'launchDate'];
  }
}
