import { Localization, Solution } from './failure.interface';

export enum Generation {
  MOD_4 = 'MOD 4'
}
export interface Service {
  brand: string;
  modId: string;
  name: string;
  generation: Generation;
  launchDate: string;
}
export interface CoreService extends Service {
  ownerName: string;
  ownerIds: string[];
  undefinedSolution: Solution;
  undefinedSolutionTitle: Localization;
  absentEvents: Solution;
  supportedLanguages: string[];
}

export interface CustomerService extends Service {
  modIds: string[];
}
