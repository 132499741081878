import { Generation, Service } from '../../shared/interfaces/service.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Input, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export abstract class ServiceEditBaseDirective<T extends Service> {
  @Output() closed = new EventEmitter<void>();
  @Output() onsubmit = new EventEmitter<T>();
  public form: FormGroup;

  public readonly MOD_ID_KEY = 'modId';
  public readonly NAME_KEY = 'name';
  public readonly DATE_KEY = 'launchDate';
  public readonly BRAND_KEY = 'brand';
  public readonly GENERATION_KEY = 'generation';

  public Generation = Generation;
  public GENERATION_KEYS = Object.keys(Generation);

  @Input() initialObject: T;
  public creationMode = true;
  submitted = false;

  close() {
    this.closed.emit();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      this.onsubmit.emit(this.form.getRawValue());
    }
  }

  initCommonFields() {
    const initial = this.initialObject;
    this.creationMode = !initial.modId && !initial.brand;

    const initialBrand = this.creationMode ? this.brands()[0] : initial.brand;
    const initialDate = this.creationMode ? new Date().toISOString().split('T')[0] : initial.launchDate;

    this.form = new FormGroup({
      [this.MOD_ID_KEY]: new FormControl({ value: initial.modId, disabled: !this.creationMode }, [Validators.required]),
      [this.NAME_KEY]: new FormControl({ value: initial.name, disabled: !this.creationMode }, [Validators.required]),
      [this.DATE_KEY]: new FormControl(initialDate, [Validators.required]),
      [this.BRAND_KEY]: new FormControl({ value: initialBrand, disabled: !this.creationMode }, [Validators.required]),
      [this.GENERATION_KEY]: new FormControl(initial.generation, [Validators.required])
    });
  }

  abstract brands(): string[];
}
