import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerService, CoreService } from '../../../../shared/interfaces/service.interface';
import { RolesService } from '../../../../shared/services/roles-service.service';
import { CoreServiceClient, CustomerServiceClient } from '../../../../shared/services/services.service';
import { ServicesBaseDirective } from '../../services-base.directive';
import { environment } from '../../../../environment';

@Component({
  selector: 'app-customer-services',
  templateUrl: './customer-services.component.html',
  styleUrls: ['./customer-services.component.scss']
})
export class CustomerServicesComponent extends ServicesBaseDirective<CustomerService> implements OnInit {
  private coreServices: CoreService[];
  features: Map<string, boolean>;

  constructor(
    private services: CoreServiceClient,
    customerServices: CustomerServiceClient,
    rolesService: RolesService
  ) {
    super(customerServices, rolesService);
  }

  ngOnInit(): void {
    this.services.getItems().subscribe((data) => {
      this.coreServices = data;
    });

    this.refreshItems();
    this.features = environment.features;
  }

  servicesProvider = () => {
    const brands: string[] = this.rolesService.getBrandsWhereMaster();

    const map = new Map();
    brands.forEach((brand) => {
      const allServicesForBrand = this.coreServices.filter((s: CoreService) => s.brand === brand);

      const generationMap = new Map();
      allServicesForBrand.forEach((service) => {
        const generation = service.generation;
        const list = generationMap.get(generation);
        if (!list) {
          generationMap.set(generation, [service]);
        } else {
          list.push(service);
        }
      });
      map.set(brand, generationMap);
    });

    return map;
  };

  fieldsToFilterOn() {
    return ['name', 'modId', 'brand', 'generation', 'launchDate'];
  }
}
