<div class="row">
  <div class="col lg-4">
    <label>
      <div class="wrapper wrapper--select">
        <select [(ngModel)]="selectedLanguageMod" required name="solutionLanguageSelect">
          <option *ngFor="let lang of getLanguages()" [ngValue]="lang">
            {{ lang }}
          </option>
        </select>
      </div>
    </label>
  </div>
</div>
<div class="row">
  <div class="col lg-12 xl-6" *ngFor="let role of roles">
    <label>
      {{ role }}
      <textarea rows="4" [ngModel]="getValue(role)" (ngModelChange)="setValue($event, role)" [class]="role"></textarea>
    </label>
  </div>
</div>
