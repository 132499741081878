import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreService } from '../../../shared/interfaces/service.interface';
import { Failure, FailureType } from '../../../shared/interfaces/failure.interface';
import { FormGroup, Validators } from '@angular/forms';
import { arrayNotContainsElement } from '../../../shared/validators/array-not-contains-element.directive';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environment';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-create-failure-modal',
  templateUrl: './failure-modal.component.html',
  styleUrls: ['./failure-modal.component.scss']
})
export class FailureModalComponent implements OnInit {
  @Output() closed = new EventEmitter<void>();
  @Output() onsubmit = new EventEmitter<Failure>();
  public failureForm: FormGroup;

  @Input() initialObject: Failure;
  @Input() service: CoreService;
  public creationMode = true;
  submitted = false;

  competenceActive: number;

  public failureRoles: string[] = environment.failureRoles;
  public FailureType = FailureType;
  public FAILURE_TYPES_KEYS = Object.keys(FailureType);

  @Input() public existingFailures: Failure[] = [];

  close() {
    this.closed.emit();
  }

  onSubmit() {
    this.submitted = true;
    if (this.failureForm.valid) {
      this.onsubmit.emit(this.failureForm.getRawValue());
    }
  }

  constructor(protected translateService: TranslateService, private fb: FormBuilder) {}

  ngOnInit() {
    this.creationMode = !this.initialObject.name;

    this.failureForm = this.fb.group({
      name: [
        '',
        this.creationMode
          ? [Validators.required, arrayNotContainsElement(this.existingFailures.map((failure) => failure.name))]
          : [Validators.required]
      ],
      statusCode: [''],
      useCase: [''],
      action: [''],
      component: [''],
      solution: [{}],
      description: [{}],
      type: [FailureType.INFO]
    });

    this.setCompetence(1);
    this.failureForm.patchValue(this.initialObject);
  }

  setCompetence(level: number) {
    if (this.competenceActive !== level) {
      this.competenceActive = level;
    } else {
      this.competenceActive = 0;
    }
  }
}
