import { Component, OnInit } from '@angular/core';
import { environment } from '../../environment';

@Component({
  selector: 'app-contactpersons',
  templateUrl: './contactpersons.component.html',
  styleUrls: ['./contactpersons.component.scss']
})
export class ContactpersonsComponent implements OnInit {
  constructor() {}

  departments: string;
  supportemail: string;
  authorizationemail: string;

  ngOnInit() {
    this.departments = environment.departments;
    this.supportemail = environment.supportemail;
    this.authorizationemail = environment.authorizationemail;
  }
}
