<div class="row row--container mar-top-large">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <div class="row">
          <h5>{{ 'HELP.HEADER' | translate }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row row--container">
  <div class="col sm-12">
    <div class="card mar-top-large">
      <div class="card__content">
        <p>
          <b>{{ 'HELP.SUBHEADER_1' | translate }}</b>
        </p>

        <br />

        <p>
          {{ 'HELP.AREAS_TEXT' | translate: { x: this.departments } }}
        </p>

        <br />
        <p>
          <b>{{ 'HELP.SUBHEADER_2' | translate }}</b>
        </p>
        <br />

        <p>
          {{ 'HELP.WHO_TO_QUESTION' | translate }}
        </p>
        <br />
        <p>
          <a href="mailto:{{ authorizationemail }}">
            {{
              'HELP.AUTHORIZATION' | translate: { x: this.authorizationemail }
            }}</a
          >
        </p>
        <br />
        <p>
          <a href="mailto:{{ supportemail }}">
            {{ 'HELP.SUPPORT' | translate: { x: this.supportemail } }}</a
          >
        </p>
        <br />
      </div>
    </div>
  </div>
</div>
