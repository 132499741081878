<div>
  <div class="row row--container">
    <button
      *ngIf="hasAnyMaster()"
      id="addServiceButton"
      class="btn btn--fab btn--primary add-button"
      type="submit"
      (click)="createNewItem()"
    >
      <i class="icon i-close"></i>
    </button>

    <label for="filter" class="col md-9"
      >{{ 'FILTER.LABEL' | translate }}
      <app-multi-value-input
        name="filter"
        [items]="filters"
        (itemAdded)="onFilterUpdated()"
        (itemRemoved)="onFilterUpdated()"
      >
      </app-multi-value-input>
    </label>
  </div>

  <div class="row row--container mar-bottom-large">
    <app-service-item
      *ngFor="let service of itemsToDisplay"
      [service]="service"
      class="col lg-4 md-6 sm-12 flex flex--equal-size"
      name="serviceWindow"
      [editable]="isMaster(service)"
      (edit)="onEdit($event)"
      (delete)="onDelete($event)"
      (showDetails)="navigateToServiceDetails(service)"
    >
      <div class="element"><i class="icon i-user"></i> {{ service.ownerName }}</div>
    </app-service-item>
  </div>

  <div class="center mar-bottom-large">
    <p class="center-div">
      {{ 'CREATE_SERVICE_MODAL.NUM_OF_RESULTS' | translate: { x: itemsToDisplay?.length, y: allItems?.length } }}
    </p>
    <div class="center-div">
      <progress value="{{ loadedPercentage || 0 }}" max="100"></progress>
    </div>
    <div class="center-div">
      <button
        class="btn btn--tertiary show-all"
        (click)="loadRemainingData()"
        [disabled]="itemsToDisplay?.length === allItems?.length"
      >
        {{ 'CREATE_SERVICE_MODAL.SHOW_ALL' | translate }}
      </button>
    </div>
  </div>
</div>

<app-core-service-edit
  *ngIf="editedItem != null"
  [initialObject]="editedItem"
  (closed)="editWindowClosed()"
  (onsubmit)="itemSubmitted($event)"
  [allServices]="allItems"
  [masterBrands]="rolesService.getBrandsWhereMaster()"
>
</app-core-service-edit>
