import { Component, OnInit } from '@angular/core';
import { Constants } from '../../shared/constants';
import { RolesService } from '../../shared/services/roles-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  readonly ALL_ROLES: string[] = Constants.ALL_ROLES;

  coreServicesTab = true;
  serviceNumber = 0;

  constructor(public rolesService: RolesService) {}

  ngOnInit(): void {}

  onServiceNumberChanged(newNumber: number) {
    this.serviceNumber = newNumber;
  }
}
