import { Component, OnInit } from '@angular/core';
import { environment } from '../../environment';

@Component({
  selector: 'app-unathorized-access',
  templateUrl: './unathorized-access.component.html',
  styleUrls: ['./unathorized-access.component.scss']
})
export class UnathorizedAccessComponent implements OnInit {
  constructor() {}

  email: string;

  ngOnInit() {
    this.email = environment.supportemail;
  }
}
