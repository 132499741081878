<div class="row">
  <div class="col lg-4">
    <label>
      <div class="wrapper wrapper--select">
        <select [(ngModel)]="selectedLanguageMod" required name="languageSelect">
          <option *ngFor="let lang of getLanguages()" [ngValue]="lang">
            {{ lang }}
          </option>
        </select>
      </div>
    </label>
  </div>
  <div class="col lg-12">
    <textarea rows="4" [(ngModel)]="areaContentMod"></textarea>
  </div>
</div>
