import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Localization } from '../../../../../shared/interfaces/failure.interface';

@Component({
  selector: 'app-localization-input',
  templateUrl: './localization-input.component.html',
  styleUrls: ['./localization-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizationInputComponent),
      multi: true
    }
  ]
})
export class LocalizationInputComponent implements OnInit, ControlValueAccessor {
  value: Localization = {};
  selectedLanguage: string;
  @Input() languages: string[] = undefined;

  writeValue(value: Localization) {
    this.value = value ? value : {};
    this.selectedLanguage = this.getLanguages()[0];
  }
  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor() {}

  ngOnInit(): void {
    this.selectedLanguage = this.getLanguages()[0];
  }

  getLanguages() {
    return this.languages ? this.languages : this.estimateLanguages();
  }

  private estimateLanguages = () => Object.keys(this.value);

  get areaContentMod() {
    return this.value[this.selectedLanguage];
  }

  set areaContentMod(content: string) {
    this.value[this.selectedLanguage] = content;
    this.propagateChange(this.value);
  }

  get selectedLanguageMod() {
    return this.selectedLanguage;
  }

  set selectedLanguageMod(lang) {
    this.selectedLanguage = lang;
  }
}
