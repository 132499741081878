import { Component, OnInit } from '@angular/core';
import { WhitelistedClient } from 'src/app/shared/interfaces/whitelisted-client';
import { WhitelistedClientsService } from 'src/app/shared/services/client-services.service';
import { NgForm } from '@angular/forms';
import { Constants, Role } from '../../shared/constants';
import { environment } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';

@Component({
  selector: 'app-admin-area',
  templateUrl: './admin-area.component.html',
  styleUrls: ['./admin-area.component.scss']
})
export class AdminAreaComponent implements OnInit {
  constructor(
    private clientService: WhitelistedClientsService,
    public rolesService: RolesService
  ) {
    this.features = environment.features;
  }

  whitelistedClients: WhitelistedClient[] = [];
  whitelistedClient: WhitelistedClient = { clientId: '' };
  componentInstance: any;
  readonly ADMIN_ROLE: string = Role.ADMIN;
  features: Map<string, boolean>;
  detectChanges() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.getWhitelistedClients();
  }

  getWhitelistedClients() {
    this.clientService
      .getWhitelistedClients()
      .subscribe((foundInstance: WhitelistedClient[]) => {
        this.whitelistedClients = foundInstance;
      });
  }

  addWhitelistedClient(myForm: NgForm) {
    this.whitelistedClient.clientId = myForm.controls.clientId.value;
    this.clientService
      .addNewWhitelistedClient(this.whitelistedClient)
      .subscribe(() => this.getWhitelistedClients());
    myForm.reset();
  }

  deleteWhitelistedClient(client: WhitelistedClient) {
    this.clientService
      .removeWhitelistedClient(client)
      .subscribe(() => this.getWhitelistedClients());
  }
}
