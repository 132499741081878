<div class="modal">
  <div class="modal__wrapper">
    <h1 class="modal__title">
      {{ 'CREATE_SERVICE_MODAL.MODAL_TITLE_' + (creationMode ? 'CREATE' : 'EDIT') | translate }}
    </h1>
    <div class="modal__close" (click)="close()"></div>

    <div class="modal__content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <ul id="serviceContent" name="serviceContent" class="accordion">
          <li id="basicInfoTab" name="basicInfoTab" [ngClass]="{ 'is-open': accordionTabActive == 0 }">
            <div class="accordion__title" (click)="setAccordionTabActive(0)">
              {{ 'CREATE_SERVICE_MODAL.BASIC_INFO' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col lg-7 md-7 wrapper wrapper--input" [ngClass]="{ 'is-inactive': !creationMode }">
                  <label for="name">
                    {{ 'CREATE_SERVICE_MODAL.NAME' | translate }}
                    <input class="form-control" formControlName="{{ NAME_KEY }}" type="text" id="name" name="name" />
                  </label>
                </div>

                <div class="col lg-5 md-5 wrapper wrapper--input" [ngClass]="{ 'is-inactive': !creationMode }">
                  <label for="modId">
                    {{ 'CREATE_SERVICE_MODAL.MOD_ID' | translate }}
                    <input
                      class="form-control"
                      formControlName="{{ MOD_ID_KEY }}"
                      type="text"
                      id="modId"
                      name="modId"
                    />
                  </label>
                </div>
                <app-form-error [visible]="form.controls[NAME_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_SERVICE_NAME_REQUIRED' | translate }}
                </app-form-error>
                <app-form-error [visible]="form.controls[MOD_ID_KEY].hasError('required') && submitted">
                  {{ 'CREATE_SERVICE_MODAL.FIELD_MOD_ID_REQUIRED' | translate }}
                </app-form-error>
              </div>
              <div class="row">
                <div class="col lg-4 md-4" [ngClass]="{ 'wrapper wrapper--input is-inactive': !creationMode }">
                  <label for="brand">
                    {{ 'CREATE_SERVICE_MODAL.BRAND' | translate }}
                    <div class="wrapper wrapper--select">
                      <select id="brand" name="brand" formControlName="{{ BRAND_KEY }}" (change)="onChangeOfBrand()">
                        <option *ngFor="let brand of brands()" [value]="brand">
                          {{ brand }}
                        </option>
                      </select>
                    </div>
                  </label>
                </div>

                <div class="col lg-4 md-4">
                  <label for="generation">
                    {{ 'CREATE_SERVICE_MODAL.GENERATION' | translate }}
                    <div class="wrapper wrapper--select">
                      <select id="generation" name="generation" formControlName="{{ GENERATION_KEY }}">
                        <option *ngFor="let generation of GENERATION_KEYS" [ngValue]="Generation[generation]">
                          {{ Generation[generation] }}
                        </option>
                      </select>
                    </div>
                  </label>
                </div>

                <div class="col lg-4 md-4">
                  <label for="date">
                    {{ 'CREATE_SERVICE_MODAL.DATE' | translate }}
                    <input class="form-control" formControlName="{{ DATE_KEY }}" id="date" type="date" name="date" />
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col lg-12 xl-7">
                  <label for="supportedLanguages">
                    {{ 'CREATE_SERVICE_MODAL.SUPPORTED_LANGUAGES' | translate }}
                    <div class="wrapper">
                      <app-multi-value-input
                        formControlName="{{ SUPPORTED_LANGUAGES_KEY }}"
                        (itemAdded)="onSupportedLanguageAdded($event)"
                        (itemRemoved)="onSupportedLanguageRemoved($event)"
                      ></app-multi-value-input>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </li>
          <li id="responsiblePersons" name="responsiblePersons" [ngClass]="{ 'is-open': accordionTabActive == 1 }">
            <div class="accordion__title" (click)="setAccordionTabActive(1)">
              {{ 'CREATE_SERVICE_MODAL.OWNER.TITLE' | translate }}
            </div>
            <div class="accordion__content">
              <div class="row">
                <div class="col lg-12 xl-5">
                  <label for="ownerName">
                    {{ 'CREATE_SERVICE_MODAL.OWNER.RESPONSIBLE' | translate }}
                    <div class="wrapper">
                      <input
                        id="ownerName"
                        formControlName="{{ PRODUCT_OWNER_NAME_KEY }}"
                        name="ownerName"
                        type="text"
                      />
                    </div>
                  </label>
                  <app-form-error [visible]="form.controls[PRODUCT_OWNER_NAME_KEY].hasError('required') && submitted">
                    {{ 'CREATE_SERVICE_MODAL.FIELD_OWNER_NAME_REQUIRED' | translate }}
                  </app-form-error>
                </div>
                <div class="col lg-12 xl-7">
                  <label>
                    {{ 'CREATE_SERVICE_MODAL.OWNER.OWNER_IDS' | translate }}
                    <div class="wrapper">
                      <app-multi-value-input formControlName="{{ PRODUCT_OWNER_ID_KEY }}"></app-multi-value-input>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </li>
          <li id="defaultDescriptions" name="defaultDescriptions" [ngClass]="{ 'is-open': accordionTabActive == 2 }">
            <div class="accordion__title" (click)="setAccordionTabActive(2)">
              {{ 'CREATE_SERVICE_MODAL.UNDEFINED_SOLUTION_TITLE' | translate }}
            </div>
            <div class="accordion__content">
              <app-localization-input formControlName="{{ UNDEFINED_SOLUTION_TITLE_KEY }}"></app-localization-input>
            </div>
          </li>
          <li id="defaultResponses" name="defaultResponses" [ngClass]="{ 'is-open': accordionTabActive == 3 }">
            <div class="accordion__title" (click)="setAccordionTabActive(3)">
              {{ 'CREATE_SERVICE_MODAL.UNDEFINED_SOLUTION' | translate }}
            </div>
            <div class="accordion__content">
              <app-solution-input formControlName="{{ UNDEFINED_SOLUTION_KEY }}"></app-solution-input>
            </div>
          </li>
          <li id="defaultNotFound" name="defaultNotFound" [ngClass]="{ 'is-open': accordionTabActive == 4 }">
            <div class="accordion__title" (click)="setAccordionTabActive(4)">
              {{ 'CREATE_SERVICE_MODAL.ABSENT_EVENTS' | translate }}
            </div>
            <div class="accordion__content">
              <app-solution-input formControlName="{{ ABSENT_EVENTS_KEY }}"></app-solution-input>
            </div>
          </li>
        </ul>
        <br />
        <div class="row">
          <div class="col sm-8"></div>
          <div class="col sm-4">
            <button type="submit" name="acceptButton" class="btn btn--primary btn--expanded save-modal right">
              {{ 'CREATE_SERVICE_MODAL.BUTTON_SAVE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
