<app-notification></app-notification>
<div class="header-body">
  <app-header></app-header>
  <div *ngIf="shouldDisplayPreloader(); else preloaderBlock">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #preloaderBlock>
  <div class="row row--container">
    <div class="col sm-4"></div>
    <div class="col sm-4 preloader preloader--golf"></div>
  </div>
</ng-template>
<app-footer></app-footer>
